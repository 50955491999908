import axios from 'axios';

import store     from '@/store';
import {apiPath} from '@/util/http';

const DisplayJeuModal   = () => import('@/components/modals/DisplayJeuModal');
const DisplayJdrModal   = () => import('@/components/modals/DisplayJdrModal');
const DisplayLivreModal = () => import('@/components/modals/DisplayLivreModal');

export const load = (borrowable, type) => axios.get(apiPath('detail_' + type, {objet: borrowable.id}));

export const loadAndDisplayDetails = (borrowable, type) => load(borrowable, type)
    .then(response => store.dispatch('modal/create', {
        component: type === 'jeux' ? DisplayJeuModal : type === 'jdrs' ? DisplayJdrModal : DisplayLivreModal,
        props: type === 'jeux' ? {jeu: response.data} : type === 'jdrs' ? {jdr: response.data} : {livre: response.data}
    }));

/**
 * Comes from https://stackoverflow.com/questions/5796718/html-entity-decode
 */
export const textDescription = borrowable => {
    let str = borrowable.description;

    if (!str || !str.length) return '';

    // this prevents any overhead from creating the object each time
    let element = document.createElement('div');

    // Specific processing for newlines are spaces instead of being stripped completely
    str = str.replace(/<br(\s?)\/>/gmi, ' ');
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';

    // Make sure description is short
    if (str.length > 128) str = str.slice(0, 124) + ' ...';

    return str;
}

export default {load, loadAndDisplayDetails, textDescription};
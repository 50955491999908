<template>
    <div>
        <calendar-links-card v-if="isCluji()"/>
        <cluji-card no-header :foldable="false">
            <calendar-options-picker v-model="toLoad"/>
            <hr/>
            <cluji-calendar :events="allEvents"/>
        </cluji-card>
    </div>
</template>

<script>
    import {apiPath}      from '@/util/http';
    import evenementUtil  from '@/util/evenement';
    import alert          from '@/util/alert';
    import {textUsername} from '@/util/text';
    import auth           from '@/util/auth';
    import colors         from '@/util/colors';
    import {formatDate}   from '@/util/date';
    import {load}         from '@/util/borrowable';

    const CalendarLinksCard     = () => import('@/components/calendar/CalendarLinksCard');
    const CalendarOptionsPicker = () => import('@/components/calendar/CalendarOptionsPicker');
    const ClujiCalendar         = () => import('@/components/ClujiCalendar');
    const ClujiCard             = () => import('@/components/ClujiCard');

    export default {
        name: "Calendar",
        components: {CalendarLinksCard, CalendarOptionsPicker, ClujiCalendar, ClujiCard},
        data: () => ({
            colors,
            toLoad: {
                jeux: [],
                livres: [],
                jdrs: [],
                evenements: true,
                local: true,
                permanences: true
            },
            events: {
                jeux: [],
                livres: [],
                jdrs: [],
                evenements: [],
                local: [],
                permanences: []
            }
        }),
        computed: {
            allEvents() {
                let events = [];

                Object.keys(this.events).map(key => events = events.concat(this.events[key]));

                return events;
            }
        },
        methods: {
            isCluji: auth.isCluji,
            loadJeuxEvents() {
                if (!this.toLoad.jeux.length) {
                    this.events.jeux = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios
                    .post(
                        apiPath('list_valid_emprunts_for_jeux'),
                        {jeux: this.toLoad.jeux.map(({id}) => id)}
                    )
                    .then(response => {
                        this.events.jeux = [];
                        for (let jeuDto of response.data) {
                            for (let emprunt of jeuDto.emprunts) {
                                this.events.jeux.push({
                                    title: jeuDto.jeu.nom + ' → ' + textUsername(emprunt.utilisateur),
                                    start: emprunt.dateDebut.date,
                                    end: emprunt.dateFin.date,
                                    backgroundColor: this.colors.jeux.background,
                                    borderColor: this.colors.jeux.border,
                                    textColor: 'white',
                                    extendedProps: {
                                        description: 'Jeu <em>' +
                                            jeuDto.jeu.nom +
                                            '</em> emprunté par <strong>' +
                                            textUsername(emprunt.utilisateur)
                                            + '</strong>'
                                    }
                                })
                            }
                        }
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des emprunts de jeux'))
                    .finally(alert.stopLoading);
            },
            loadLivresEvents() {
                if (!this.toLoad.livres.length) {
                    this.events.livres = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios
                    .post(
                        apiPath('list_valid_emprunts_for_livres'),
                        {livres: this.toLoad.livres.map(({id}) => id)}
                    )
                    .then(response => {
                        this.events.livres = [];
                        for (let livreDto of response.data) {
                            for (let emprunt of livreDto.emprunts) {
                                this.events.livres.push({
                                    title: livreDto.livre.nom + ' → ' + textUsername(emprunt.utilisateur),
                                    start: emprunt.dateDebut.date,
                                    end: emprunt.dateFin.date,
                                    backgroundColor: this.colors.livres.background,
                                    borderColor: this.colors.livres.border,
                                    textColor: 'white',
                                    extendedProps: {
                                        description: 'Livre <em>' +
                                            livreDto.livre.nom +
                                            '</em> emprunté par <strong>' +
                                            textUsername(emprunt.utilisateur) +
                                            '</strong>'
                                    }
                                })
                            }
                        }
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des emprunts de livres'))
                    .finally(alert.stopLoading);
            },
            loadJdrsEvents() {
                if (!this.toLoad.jdrs.length) {
                    this.events.jdrs = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios
                    .post(
                        apiPath('list_valid_emprunts_for_jdrs'),
                        {jdrs: this.toLoad.jdrs.map(({id}) => id)}
                    )
                    .then(response => {
                        this.events.jdrs = [];
                        for (let jdrDto of response.data) {
                            for (let emprunt of jdrDto.emprunts) {
                                this.events.jdrs.push({
                                    title: jdrDto.jdr.nom + ' → ' + textUsername(emprunt.utilisateur),
                                    start: emprunt.dateDebut.date,
                                    end: emprunt.dateFin.date,
                                    backgroundColor: this.colors.jdrs.background,
                                    borderColor: this.colors.jdrs.border,
                                    textColor: 'white',
                                    extendedProps: {
                                        description: 'JdR <em>' +
                                            jdrDto.jdr.nom +
                                            '</em> emprunté par <strong>' +
                                            textUsername(emprunt.utilisateur) +
                                            '</strong>'
                                    }
                                })
                            }
                        }
                    })
                    .catch(() => this.$toaster.error('Impossible de charger la liste des emprunts de jeux de rôle'))
                    .finally(alert.stopLoading);
            },
            loadEvenementsEvents() {
                if (!this.toLoad.evenements) {
                    this.events.evenements = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios.get(apiPath('list_evenements_quickview', {limit: 100}))
                    .then(response => this.events.evenements = response.data.map(evenement => ({
                        title: evenement.nom,
                        start: evenement.dateDebut.date,
                        end: evenement.dateFin.date,
                        backgroundColor: this.colors.evenements.background,
                        borderColor: this.colors.evenements.border,
                        textColor: 'white',
                        url: this.$router.resolve(evenementUtil.linkToDetail(evenement, this.$route)).href,
                        extendedProps: {
                            route: evenementUtil.linkToDetail(evenement, this.$route),
                            description: evenement.resume
                                ? ('<strong>' + evenement.nom + ' : </strong>' + evenement.resume)
                                : null // Fall back to displaying title
                        }
                    })))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des événements'))
                    .finally(alert.stopLoading);
            },
            loadLocalEvents() {
                if (!auth.isCluji() || !this.toLoad.local) {
                    this.events.local = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios.get(apiPath('list_valid_reservations_quickivew', {limit: 100}))
                    .then(response => this.events.local = response.data.map(reservation => ({
                        title: textUsername(reservation.utilisateur),
                        start: reservation.dateDebut.date,
                        end: reservation.dateFin.date,
                        backgroundColor: this.colors.local.background,
                        borderColor: this.colors.local.border,
                        textColor: 'white',
                        extendedProps: {
                            description: 'Local réservé par ' + textUsername(reservation.utilisateur)
                        }
                    })))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des réservations du local'))
                    .finally(alert.stopLoading);
            },
            loadPermanencesEvents() {
                if (!this.toLoad.permanences) {
                    this.events.permanences = [];
                    return Promise.resolve();
                }

                alert.loading();

                return this.axios.get(apiPath('list_calendar_events', {limit: 100}))
                    .then(response => this.events.permanences = response.data.map(permanence => ({
                        title: permanence.nom || 'Permanence',
                        start: permanence.dateDebut.date,
                        end: permanence.dateFin.date,
                        backgroundColor: this.colors.permanences.background,
                        borderColor: this.colors.permanences.border,
                        textColor: 'white',
                        url: permanence.url || '#',
                        extendedProps: {
                            description: (permanence.nom || 'Permanence') + ' - de ' +
                                formatDate(permanence.dateDebut.date, 'H[h]mm') + ' à ' +
                                formatDate(permanence.dateFin.date, 'H[h]mm')
                        }
                    })))
                    .catch(() => this.$toaster.error('Impossible de charger la liste des permanences'))
                    .finally(alert.stopLoading);
            },
            loadData() {
                this.loadJeuxEvents()
                    .then(() => this.loadLivresEvents())
                    .then(() => this.loadJdrsEvents())
                    .then(() => this.loadEvenementsEvents())
                    .then(() => this.loadLocalEvents())
                    .then(() => this.loadPermanencesEvents());
            }
        },
        watch: {
            "toLoad.jeux"() {
                this.loadJeuxEvents();
            },
            "toLoad.livres"() {
                this.loadLivresEvents();
            },
            "toLoad.jdrs"() {
                this.loadJdrsEvents();
            },
            "toLoad.local"() {
                this.loadLocalEvents();
            },
            "toLoad.evenements"() {
                this.loadEvenementsEvents();
            },
            "toLoad.permanences"() {
                this.loadPermanencesEvents();
            }
        },
        mounted() {
            if (this.$route.query.jeu)
                load({id: this.$route.query.jeu}, 'jeux')
                    .then(response => this.toLoad.jeux = [response.data]);
            if (this.$route.query.livre)
                load({id: this.$route.query.livre}, 'livres')
                    .then(response => this.toLoad.livres = [response.data]);

            this.loadData();
        }
    }
</script>
